import React from 'react';
import Content from '../views/RepairShop/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'What is the cost of a workshop solution?',
  lang: 'en',
  description: 'Request price for car workshop service by filling out the quotation form here. Empower your auto repair shop with a powerful solution.',
  meta: [{
    name: 'keywords',
    content: 'Workshop solution'
  }]
}}/>