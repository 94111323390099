import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import QuestionTitle from '../QuestionTitle';
import Next from '../Next';
import template from './template';
import styles from './styles.module.sass';

const Option = ({
  text,
  Icon,
  formData,
  value,
  selected = !!formData[value],
  setFormData,
  i
}) => (
  <div className={clsx(
      styles.optionWrapper,
      selected ? styles.optionWrapperSelected : ''
    )}>
    <div className={styles.optionContainer}
      onClick={() => {
        if(selected) setFormData({
          ...formData,
          [value]: false
        });
        else setFormData({
          ...formData,
          [value]: true
        });
      }}
      onKeyDown={() => {
        if(selected) setFormData({
          ...formData,
          [value]: false
        });
        else setFormData({
          ...formData,
          [value]: true
        });
      }}
      role = {'button'}
      tabIndex={i}>
      <Icon/>
      <Typography variant='subtitle2'
        weight='bold'
        className={styles.iconText}>
        {text}
      </Typography>
    </div>
  </div>
);

export default ({ setFormData, formData, onNext }) => (
  <>
    <QuestionTitle text={`${i18n('Which customer types do you want to connect to your repair shop?')}`}/>
    <Grid container className={styles.container} justifyContent='center'>
      {template.map((t, i) => (
        <Option key={t.value}
          formData={formData}
          setFormData={setFormData}
          i={i}
          {...t}/>
      ))}
    </Grid>
    <Next onClick={onNext}
      disabled={!(formData.private || formData.business)}/>
  </>
)