import React from 'react';
import 'whatwg-fetch';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/repairShop';
import ContactDa from './ContactDa';
import Done from './Done';
import ErrorMessage from './ErrorMessage';
import styles from './styles.module.sass';
import sitemap from '../../../data/sitemap';
import Typography from '../../../components/Typography';
import Partners from './Partners';

const postToForm = data => fetch(
'https://api.hsforms.com/submissions/v3/integration/submit/5142926/856c0f88-d86c-464d-80c1-a3bf3727f269',
{
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    'submittedAt': Date.now(),
    'fields': [
      {
        'name' : 'email',
        'value': data.email
      }, {
        'name' : 'phone',
        'value': data.phone ? data.phone : ''
      }, {
        'name': 'firstname',
        'value': data.name ? data.name : ''
      }, {
        'name' : 'types_of_customers',
        'value': Object.keys(data['types-of-customers'])
          .filter(k => data['types-of-customers'][k])
          .join(';')
      }
    ],
    'context': {
      'pageUri': i18n('_url:_') + i18n('_url:repair-shop-pricing'),
      'pageName': 'Repair shop pricing'
    },
    'legalConsentOptions': {
      "consent": {
        "consentToProcess": true,
        "text": "I agree to allow OBI+ to store and process my personal data.",
        "communications": [
          {
            "value": true,
            "subscriptionTypeId": 999,
            "text": "I agree to receive marketing communications from OBI+."
          }
        ]
      }
    }
  })
})

export default ({ seo, location }) => {
  const [ error, setError ] = React.useState(false);
  const [ step, setStep ] = React.useState(0);
  const [ formData, setFormData ] = React.useState({
    'types-of-customers': {
      'private': true,
      'business': true
    }});
  // const onNext = () => setStep(step + 1);
  const setFormKey = key => data => setFormData({
    ...formData,
    [key]: data
  });

  return (
    <Layout overlay page={sitemap.industries}
      subpage={sitemap.repairShop.price}
      subheader={Subheader}
      url='_url:repair-shop-pricing'>
      <SEO {...seo} location={location}/>
      <div className={styles.container}>
        {step === 0
          ? <Grid container alignItems='flex-start' alignContent='flex-start' justifyContent='center' className={styles.daFormWrap}>
                <Grid item container xs={12}>
                    <Typography
                        variant='h1'
                        color='dark'
                        weight='bold'
                        className={styles.componentTitle}>
                            {i18n('Get started')}
                    </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={6} className={styles.columnLeft}>
                    <Typography
                        variant='h2'
                        color='dark'
                        weight='bold'
                        className={styles.title}>
                            {i18n('Se Vehicle Care i aktion')}
                    </Typography>
                    <Typography variant='subtitle2'
                        weight='light'
                        className={styles.subtitle}>
                        {i18n('Hold dig informeret om tilstanden på hvert tilsluttet køretøj og vær tættere på den enkelte kunde.')}
                    </Typography>
                        <div style={{position: 'relative', paddingBottom: '56.25%', height: '350px', width: '100%', margin: '64px 0'}}>
                            <iframe src="https://www.loom.com/embed/951967e2893d49b29afa2bb03d26981c" frameBorder="0" allowFullScreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} title={'OBI+ Vehicle Care - Dashboard'}></iframe>
                        </div>
                    <Typography
                        variant='h2'
                        color='dark'
                        weight='bold'
                        className={styles.title}>
                            {i18n('Spørgsmål?')}
                    </Typography>
                    <Typography variant='subtitle2'
                        weight='light'
                        className={styles.subtitle}>
                        {i18n('Ring til os på 96 73 76 73 eller send en email til hello@obiplus.com')}
                    </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={6} className={styles.columnRight}>
                    <ContactDa
                        email={formData['email']}
                        phone={formData['phone']}
                        name={formData['name']}
                        setEmail={setFormKey('email')}
                        setPhone={setFormKey('phone')}
                        setName={setFormKey('name')}
                        onNext={() => postToForm(formData)
                        .then(res => res.json()).then(res => {
                            if(res.status !== 'error') {
                            setStep(step + 1);
                            setError(false);
                            } else setError(true);
                        }).catch(() => setError(true))}/>
                </Grid>
            </Grid>
          : <div className={styles.formDone}><Done/></div>}
        <ErrorMessage error={error}/>
        <Partners/>
      </div>
    </Layout>
  )
}