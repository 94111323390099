import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import Next from '../Next';
import styles from './styles.module.sass';

export default ({
  email,
  phone,
  name,
  setEmail,
  setPhone,
  setName,
  onNext
}) => (
  <>    
    <Typography variant='h2'
      weight='bold'
      className={styles.title}>
      {i18n('Bliv en partner')}
    </Typography>

    <div className={styles.container}>
      <input type='email' className={styles.input}
        defaultValue={email}
        onChange={e => setEmail(e.target.value)}
        placeholder={i18n('Email address')}/>
    </div>
    <div className={clsx(
        styles.container,
        styles.phoneContainer
      )}>
      <input type='text' className={styles.input}
        defaultValue={name}
        onChange={e => setName(e.target.value)}
        placeholder={i18n('Name')}/>
    </div>
    <div className={clsx(
        styles.container,
        styles.nameContainer
      )}>
      <input type='tel' className={styles.input}
        defaultValue={phone}
        onChange={e => setPhone(e.target.value)}
        placeholder={i18n('Telephone number (optional)')}/>
    </div>
    <Typography className={styles.termsContainer}
      variant='subtitle1'
      weight='light'
      color='dark'>
      {i18n('By clicking \'Send me an offer\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.')}
      <br/>
      <br/>
      {i18n('You can unsubscribe from these messages at any time. For more information on how to unsubscribe, about our practices, and how we are committed to protecting and respecting your privacy, read our')}&nbsp;
      <Link to='/privacy-policy'
        target='blank'
        className={styles.anchor}>
        {i18n('Terms of Use and Privacy Policy')}
      </Link>.
    </Typography>
    <Next disabled={!email || !name}
      text={i18n('Send')}
      onClick={onNext}/>
  </>
)